import React, { useState } from 'react';
import {
  Typography,
  Box,
  IconButton,
  Modal,
  useMediaQuery,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { OurTeamStyles } from './styles/AboutStyles';
import { withStyles } from '@material-ui/styles';
import MemberCard from './MemberCard';

/**
 * Wrapper for all team member cards, displays them side-by-side as width allows
 */
const MemberCardsWrapper = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(6),
    justifyContent: 'center',
    maxWidth: 544,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 250,
    },
  },
}))(Box);

const TeamOther = (props) => {
  const { team, heading } = props;
  const classes = OurTeamStyles();
  // determines whether the modal is open or closed
  const [modal, setModal] = useState(false);
  // determines which member is shown in the modal
  const [activeMember, setActiveMember] = useState();
  // open modal handler
  const handleOpenBio = (member) => (event) => {
    setModal(true);
    setActiveMember(member);
  };

  return (
    <Box className={classes.teamOther}>
      <Typography variant="overline">{heading}</Typography>
      <MemberCardsWrapper className={classes.wrapper}>
        {team.map((member, i) => (
          <MemberCard
            key={i}
            {...member}
            width={100}
            onOpenBio={handleOpenBio(member)}
          />
        ))}
      </MemberCardsWrapper>
      <Modal
        disableAutoFocus
        disablePortal
        disableRestoreFocus
        open={modal}
        onClose={() => setModal(false)}
      >
        <Box className={classes.modal}>
          <Box className={classes.iconWrapper}>
            <IconButton
              className={classes.iconButton}
              onClick={() => setModal(false)}
            >
              <CancelIcon className={classes.icon} />
            </IconButton>
          </Box>
          <MemberCard full {...activeMember} />
        </Box>
      </Modal>
    </Box>
  );
};

export default TeamOther;
