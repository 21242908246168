import React from 'react'
import {
  Typography,
  Box,
  makeStyles,
} from "@material-ui/core";
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles(theme => ({
  textBox: {
    marginBottom: theme.spacing(4),
    maxWidth: theme.spacing(62.5),
    [theme.breakpoints.down('md')]: {
      maxWidth: '40%',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
      maxWidth: '100%',
    },  
  },
  h3Hero: {
    [theme.breakpoints.down('md')]: {
      fontSize: '17px!important',
    },
  }
}))

const HomeHeroText = (props) => {
  const { 
    heading, 
    subheading,
    textBoxStyles
  } = props

  const classes = useStyles()
  
  return (
    <Box className={textBoxStyles ? textBoxStyles : classes.textBox}>
      <Typography variant="overline">{heading}</Typography>
      <Typography variant="h3" className={classes.h3Hero}><ReactMarkdown>{subheading}</ReactMarkdown></Typography>   
    </Box>
  )
}

export default HomeHeroText
