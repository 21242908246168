  import React from 'react';
import {
  Typography,
  Box,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    friendsSection: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 720,
      paddingTop: theme.spacing(4),
      '& > span': {
        textAlign: 'center',
        marginBottom: theme.spacing(4),
      }  
    },
    friendsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: 60,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    friend: {
      display: 'inline-flex',
      '& img': {
        height: 200,
        objectFit: 'contain',
        width: 200,
        [theme.breakpoints.down('xs')]: {
          height: 'auto',
        },
      }
    }
  }),
);

const Friend = ({ logo, link, name, ...props}) => {
  const classes = useStyles()

  return (
    <a className={classes.friend} href={link}>
      <img src={logo} alt={name} />
    </a>
  )
}

const Friends = ({ heading, friends, ...props }) => {
  const classes = useStyles()
  
  return (
    <Box className={classes.friendsSection}>
      <Typography variant="overline">{heading}</Typography>
      <Box className={classes.friendsWrapper}>
        {friends.map((item, i) => (
          <Friend
            key={i}
            classNeme={classes.friend}
            {...item}
          />
        ))}
      </Box>
    </Box>
  )
}
  
export default Friends
