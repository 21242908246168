import React from 'react'
import { Hero } from '@hyperobjekt/material-ui-website';
import { makeStyles } from '@material-ui/core';
import HeroText from './HeroText';

const useStyles = makeStyles((theme, props) => ({
  hero: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: props => `url(${props.image})`,
    backgroundPosition: props => {return props.backgroundPosition ? props.backgroundPosition : 'center'},
    backgroundRepeat: 'no-repeat',
    backgroundSize: props => props.backgroundSize || 'cover',
    minHeight: '300px!important',
    paddingBottom: theme.spacing(9) + 'px!important',
    paddingTop: theme.spacing(9) + 'px!important',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(9) + 'px!important',
      paddingTop: theme.spacing(9) + 'px!important',
    }
  },
}))

const PageHero = ({ heading, subheading, ...props}) => {
  const classes = useStyles(props)
  
  return (
    <Hero 
      className={classes.hero}
    >
      <HeroText
        heading={heading}
        subheading={subheading}
      />
    </Hero>
  )
}

export default PageHero
