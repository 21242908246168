import { makeStyles } from "@material-ui/core"

import HomeHero from "../../../../static/graphics/heroGraphic.png"

import { FONT } from "../../../gatsby-theme-hypercore/theme";

export const HomePageStyles = makeStyles(theme => ({
  hero: {
    height: "600px",
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    background: `url(${HomeHero}) no-repeat right top`,
    [theme.breakpoints.up('md')]: {
      backgroundSize: "750px",
      backgroundPosition: 'calc(50% + 335px) 120%',
    },
    [theme.breakpoints.only('sm')]: {
      backgroundSize: "700px",
      backgroundPosition: 'calc(45vw) 155%',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundPosition: 'center -130px',
      backgroundSize: "400px",
      height: "100%",
      paddingBottom: theme.spacing(5) + 'px!important',
      paddingTop: theme.spacing(41) + 'px!important',
    },
    '& span': {
      color: theme.palette.secondary.main,
      display: 'block',
      fontWeight: 600,
      marginBottom: theme.spacing(2),
    },
    '& h3': {
      fontWeight: 500,
      lineHeight: 1.6,
      margin: '0!important',
    },
    '& p': {
      margin: '0!important',
    },
  },
  heroLinksWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(1),
    },
    '& a': {
      color: theme.palette.secondary.main,
      fontFamily: FONT,
      fontSize: 17,
      fontWeight: 700,
      textDecoration: 'none',
    }
  },
}))