import { makeStyles } from '@material-ui/core';

export const AboutUsPage = makeStyles((theme) => ({
  contentWrapper: {
    paddingBottom: theme.spacing(8) + 'px!important',
    paddingTop: theme.spacing(8) + 'px!important',
  },
  
  content: {
    marginBottom: theme.spacing(8),
    maxWidth: theme.spacing(70),
    '& h3': {
      marginBottom: theme.spacing(2),
    },
  },

  whatDrivesUs: {
    marginBottom: theme.spacing(8),
    maxWidth: theme.spacing(70),
    '& h2': {
      color: 'black',
      fontSize: 32,
      letterSpacing: 0,
      lineHeight: 1.6,
    },
    '& hr': {
      backgroundColor: 'black',
      border: 'none',
      height: 6,
      marginBottom: theme.spacing(8),
      marginTop: theme.spacing(8),
    },
  },

  centerAlign: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },
  },

  hlText: {
    maxWidth: theme.spacing(70),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      maxWidth: theme.spacing(40),
      marginTop: theme.spacing(1),
    },
  },
  hlBox: {
    width: '587px',
    paddingBottom: theme.spacing(0),
    borderBottom: '0em solid',
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      width: '300px',
      paddingBottom: theme.spacing(1),
      borderBottom: '.2em solid',
      marginBottom: theme.spacing(1),
    },
  },
  logo: {
    width: '587px',
    margin: '50px 0 0 0',

    [theme.breakpoints.down('sm')]: {
      width: '300px',
    },

    '& > *': {
      paddingBottom: '15px',
    },
  },
  ourFriends: {
    width: '800px',
    [theme.breakpoints.down('md')]: {
      width: '360px',
    },

    marginTop: '20px',
  },
  textBoxStyles: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    margin: '0 auto',
    textAlign: 'center',
    maxWidth: '600px',

    paddingBottom: theme.spacing(0),
    '& span': {
      display: 'block',
      paddingBottom: '0!important',
    },
    '& h3:last-child': {
      margin: '0!important',
    }
  },
}));

export const OurTeamStyles = makeStyles((theme) => ({
  blueContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    paddingBottom: theme.spacing(6), 
    paddingTop: theme.spacing(6), 
    position: 'relative',
    textAlign: 'center',
    width: '100%',
    '&:before': {
      backgroundColor: '#E8F5FF',
      content: '""',
      display: 'block',
      height: '100%',
      left: 'calc((100vw - 100%)/-2)',
      position: 'absolute',
      top: 0,
      width: '100vw',
    },
    '& > *': {
      position: 'relative',
    },
    '& > span': {
      marginBottom: theme.spacing(4),
      textAlign: 'center',
    },
  },

  modal: {
    backgroundColor: '#E8F5FF',

    maxWidth: theme.spacing(90),
    padding: theme.spacing(4),

    [theme.breakpoints.down('md')]: {
      width: theme.spacing(70),
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 32px)',
    },

    position: 'absolute',
    top: '50%',
    left: '50%',

    transform: 'translate(-50%, -50%)',
    width: '100%',
  },
  thumbnail: {
    width: '150px',
    height: '150px',
    marginRight: '10px',
  },
  itemStyles: {
    backgroundColor: '#E8F5FF',

    maxWidth: theme.spacing(40),
    padding: theme.spacing(3),
    margin: '0 0 10px 0',
  },
  modalItemStyles: {
    display: 'flex',
    backgroundColor: '#E8F5FF',

    maxWidth: theme.spacing(200),
    height: theme.spacing(40),
    padding: theme.spacing(1),

    margin: '0 0 10px 0',
  },
  copyStyles: {
    display: 'flex',
    flexDirection: 'column',

    padding: `theme.spacing(3) 0`,

    '& > *': {
      paddingBottom: theme.spacing(2),
    },
  },
  iconWrapper: {
    position: 'absolute',
    right: 4,
    top: 4,
  },
  iconButton: {
    padding: 0,
    marginLeft: '2px',
    verticalAlign: 'top',
  },
  icon: {
    color: '#064493',
  },

  teamOther: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
    textAlign: 'center',
    width: '100%',
    '& > span': {
      marginBottom: theme.spacing(4),
      textAlign: 'center',
    },
  },
  wrapper: {
    '& img.mc_thumbnail': {
      height: 100,
      width: 100,
    }
  }
}));
