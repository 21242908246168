import React from 'react';
import PageHero from '../general/PageHero';
import { ProjectsPageStyles } from './styles/ProjectsPageStyles';
import heroBg from '../../../static/graphics/projectsBg.svg'
import data from '../../../content/data/projects.json'
import ProjectItem from './ProjectItem'
import ProductItem from './ProductItem'
import { Typography, Box } from '@material-ui/core'

const ProjectsPage = () => {
  const classes = ProjectsPageStyles();

  return (
    <>
      <PageHero
        backgroundPosition={'left'}
        backgroundSize={'cover'}
        image={heroBg}
        heading={data.hero.title}
        subheading={data.hero.introText}
      />

      <Projects />
      <FutureProjects />

      <ResearchProducts />
    </>
  );
};

const Projects = () => {
  const classes = ProjectsPageStyles();

  return (
    <Box className={classes.projects}>
      {data.projects.map((project, i) => {
        if (!project.upcoming) {
          return (
            <ProjectItem
              key={i} 
              image={project.image}
              title={project.title}
              description={project.description}
              findings={project.findings}
            />
          )  
        }
      })}
    </Box>
  );
};

const FutureProjects = () => {
  const classes = ProjectsPageStyles();

  return (
    <Box className={classes.upcomingProjects}>
      <Typography variant="h6" className={classes.sectionTitle}>{data.upcomingProjectsHeader || 'Upcoming Projects'}</Typography>
      {data.projects.map((project, i) => {
        if (project.upcoming) {
          return (
            <ProjectItem 
              key={i} 
              image={project.image}
              title={project.title}
              description={project.description}
              findings={project.findings}
            />
          )  
        }
      })}
    </Box>
  );
};

const ResearchProducts = () => {
  const classes = ProjectsPageStyles();

  return (
    <Box className={classes.reserchProducts}>
      <Typography variant="h6" className={classes.sectionTitle}>{data.publicationsHeader || 'Lab Research Publications'}</Typography>
      {data.products.map((product, i) => {

        return (
          <ProductItem 
            key={i} 
            title={product.title}
            description={product.description}
            date={product.date}
            file={product.file}
            link={product.link}
          />
        )  
      })}
    </Box>
  );
};

export default ProjectsPage
