import React from 'react';
import BaseMobileNavigation from 'gatsby-theme-hypersite/src/header/mobile-navigation';
import menuCollapsed from '../../../static/icons/menu-collapsed.svg'

/**
 * Duplicate of base component mobile nav, but override menu icon
 */
const MobileNavigation = (props) => {
  return (
    <BaseMobileNavigation
      {...props}
      buttonLabel={<img src={menuCollapsed} style={{height: 44, width: 44}} />}
    />
  );
};

export default MobileNavigation; 