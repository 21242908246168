import React from 'react';

import { 
  Link, 
  Typography, 
  Box,
  withStyles
} from '@material-ui/core';

const editLede = (lede) => {
  const maxLen = 198;
  let editedLede;

  lede.length > maxLen
    ? (editedLede = lede.slice(0, 197).concat('...'))
    : (editedLede = lede);

  return editedLede;
};

const NewsItemWrapper = withStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '&:nth-child(even)': {
      backgroundColor: '#E8F5FF',
    },
    '& .news-item-wrapper': {
      display: 'flex',
      gap: theme.spacing(2.5),
      maxWidth: 748,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(9),
      paddingTop: theme.spacing(9),
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    //thumbnail styles
    '& .news_thumbnail': {
      order: 2,
      width: '208px',
      height: '208px',
      objectFit: 'cover',
      [theme.breakpoints.down('xs')]: {
        order: 0,
        width: '100%',
        height: '50vw',  
        minHeight: 200,
      },
    },
    '& a': {
      color: '#064493',
    },
    '& .dhNewsHeader': {
      fontSize: '26px!important',
      lineHeight: 1.4,
    },
    '& .lede': {
      fontSize: '16px!important',
      lineHeight: 1.6,
      marginBottom: theme.spacing(5)
    },
    '& span[class*=caption]': {
      fontWeight: 600,
      fontSize: 12,
      letterSpacing: '-.01em',
    }
  }
}))(Box)

const NewsItem = (props) => {

  const { item } = props;
  return (
    <NewsItemWrapper>
      <Box className='news-item-wrapper'>
        <img className="news_thumbnail" src={item.thumbnail} />
        <Box>
          <Link href={item.article[0].url} target='_blank'>
            <Typography className="dhNewsHeader">
              {item.article[0].title}
            </Typography>
          </Link>
          <Typography className='lede' variant="body2">{editLede(item.lede)}</Typography>
          <Typography variant="caption">
            {item.source[0].name} | {item.source[0].date}
          </Typography>
        </Box>
      </Box>
    </NewsItemWrapper>
  );
};

export default NewsItem;
 