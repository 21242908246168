import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import hereLabLogo from '../../static/icons/hereLabLogo.svg';
import { Link } from 'gatsby';

const Logo = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const title = 'HERE Lab';
  const logo = (
    <img src={hereLabLogo} width="196" alt={title} style={{ verticalAlign: 'bottom' }} />
  );
  return <Link to="/">{logo}</Link>;
};

export default Logo;
