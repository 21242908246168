import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core"
import {
  Container,
  Header as BaseHeader,
  Block,
} from "@hyperobjekt/material-ui-website";
import DesktopNavigation from "./desktop-navigation"
import MobileNavigation from "./mobile-navigation"
import Logo from "../logo"
import { GatsbyLink } from "gatsby-material-ui-components"
import { SkipNavLink } from "@reach/skip-nav"

const useStyles = makeStyles(props => ({
  headerContainer: {
    '&.scroll-active': {
      '& header': {
        height: '64px!important',
      },
      '& img': {
        height: '33px',
      },
      '& .MuiButton-label': {
        '& img': {
          height: '36px!important',
          width: '36px!important',
        }
      }  
    },
    '& img': {
      height: '56px',
      objectFit: 'cover',
      objectPosition: 'top',
      position: 'relative',
      top: 2,
      transition: 'all .25s ease-in-out',
    },
  },
  navContainer: {
    display: "flex",
    flexDirection: "row!important",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const Header = ({ logo, links, title, social, children, ...props }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollActive, setScrollActive] = useState(false);
  
  const classes = useStyles();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    if (position > 0) {
      setScrollActive(true);
    } else {
      setScrollActive(false);
    }
  };
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const activeClass = ' scroll-active'

  return (
    <Container className={scrollActive ? classes.headerContainer +  activeClass : classes.headerContainer}>
      <BaseHeader sticky stickyOffset={0} {...props}>
        <SkipNavLink />
        <Container className={classes.navContainer}>
          <Logo title={title} logo={logo} />
          <DesktopNavigation
            LinkComponent={GatsbyLink}
            isGatsbyLink={true}
            LinkProps={{
              partiallyActive: true,
            }}
            links={links}
          />
          <MobileNavigation links={links} />
          {children}
        </Container>
      </BaseHeader>
    </Container>
  )
}

export default Header;
