import React from 'react';
import { Hero } from '@hyperobjekt/material-ui-website';
import SplitHeader from './SplitHeader';
import { Link } from 'gatsby';
import { HomePageStyles } from './styles/HomeStyles';
import HomeHeroText from './HomeHeroText';
import homeData from '../../../content/data/home.json';

const handleScrollClick = () => {
  const scrollTo = document.getElementById('scrollTo');
  scrollTo.scrollIntoView();
};

const HomePage = () => {
  const classes = HomePageStyles();
  const linksObj = homeData.hero.links

  return (
    <div id="heroScrollTo" className={classes.backgroundDiv}>
      <div id="homeHero">
        <Hero
          ContainerProps={{
            justifyContent: 'flex-start',
          }}
          className={classes.hero}
        >
          <HomeHeroText
            heading={homeData.hero.title}
            subheading={homeData.hero.introText}
          />
          <div className={classes.heroLinksWrapper}>
            {linksObj.map((link, i) => {
              return (<Link key={i} to={link.link} handleClick={handleScrollClick}>{link.linkText}</Link>)
            })}
          </div>
        </Hero>
      </div>

      <SplitHeader />
    </div>
  );
};

export default HomePage;
