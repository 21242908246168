import React from 'react';
import { 
  makeStyles,
  Box,
  Typography
} from '@material-ui/core'
import downloadIcon from '../../../static/icons/download.svg'
import LinkIcon from '../../../static/icons/link.svg'

const useStyles = makeStyles(theme => ({
  productContainer: {
    columnGap: 30,
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto',
    maxWidth: 768,
    padding: '20px 24px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      columnGap: 0,
      rowGap: 20,
    },
  },
  titleWrapper: {
    width: '55%',
    [theme.breakpoints.down('xs')]: {
      order: 1,
      width: '100%',
    },
  },
  title: {
    fontSize: '1rem!important',
    fontWeight: 400,
    marginTop: 0 + '!important',
  },
  description: {
    fontSize: '.875rem!important',
    marginBottom: 0 + '!important',
    marginTop: 0 + '!important',
  },
  dateWrapper: {
    flexBasis: 50,
    [theme.breakpoints.down('xs')]: {
      flexBasis: '50%',
      order: 2,
    },
  },
  date: {
    display: 'flex',
    fontSize: '15px!important',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      margin: 0 + '!important',
    },
  },
  fileWrapper: {
    flexBasis: 100,
    [theme.breakpoints.down('xs')]: {
      order: 2,
      flexBasis: '50%',
    },
    '& p': {
      [theme.breakpoints.down('xs')]: {
        margin: 0 + '!important',
      },  
    }
  },
  downloadA: {
    alignItems: 'center',
    color: '#000',
    display: 'flex',
    fontSize: '15px!important',
    gap: 5,
    justifyContent: 'flex-end',
    letterSpacing: 0,
    marginLeft: 'auto',
    textDecoration: 'none',
    '& img': {
      height: 15,
      marginBottom: -2,
      width: 15,
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
}));

const ProductItem = ({ title, description, date, file, link, ...props }) => {
  const classes = useStyles()
  const fileEl = file ?  <Typography variant="body1"><a className={classes.downloadA} download href={file}><img src={downloadIcon} /><span>Download</span></a></Typography> : false
  const linkEl = link ?  <Typography variant="body1"><a className={classes.downloadA} href={link}><img src={LinkIcon} /><span>Link</span></a></Typography> : false

  return (
    <Box className={classes.productContainer}>
      <Box className={classes.titleWrapper}>
        <Typography className={classes.title} variant="body1">{title}</Typography>
        <Typography className={classes.description} variant="body1">{description}</Typography>
      </Box>
      <Box className={classes.dateWrapper}>
        <Typography className={classes.date} variant="body1">{new Date(date).getFullYear()}</Typography>
      </Box>
      <Box className={classes.fileWrapper}>
        {fileEl}
        {linkEl}
      </Box>
    </Box>
  );
};

export default ProductItem
