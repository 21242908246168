import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { Block, Hero } from '@hyperobjekt/material-ui-website';
import { AboutUsPage } from './styles/AboutStyles';
import OurTeam from './OurTeam';
import TeamOther from './TeamOther';
import Friends from './Friends';
import ReactMarkdown from 'react-markdown';

import AboutHero from '../../../static/graphics/aboutHeroBg.svg';
import aboutUsObj from '../../../content/data/about.json';
import AboutUsWrapper from './AboutUsWrapper';
import PageHero from '../general/PageHero';

const AboutUs = () => {
  const classes = AboutUsPage();
  // const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const components = {
    h2: props => <Typography variant="h2" {...props} />,
    h3: props => <Typography variant="h3" {...props} />,
    h4: props => <Typography variant="h4" {...props} />,
    h5: props => <Typography variant="h5" {...props} />,
    h6: props => <Typography variant="h6" {...props} />,
    p: props => <Typography variant="body1" {...props} />,
  }

  return (
    <AboutUsWrapper>
      <PageHero
        backgroundPosition={'left'}
        image={AboutHero}
        heading={'The Higher Education Race & The Economy (HERE) LAB'}
        subheading={'The Lab seeks to explain uneven racial and class distributions of resources and risk in higher education systems and economies—and to help reverse those inequalities.'}
      />
      <Block className={classes.contentWrapper}>
        <Box className={classes.centerAlign}>
          <Box>
            <ReactMarkdown 
              className={classes.content}
              components={components}
              >{aboutUsObj.content}
            </ReactMarkdown>
          </Box>

          <Box className={classes.whatDrivesUs}>
            <Typography variant='h2'>{aboutUsObj.whatDrivesUs.heading}</Typography>
            <hr />
            <ReactMarkdown  components={components}>{aboutUsObj.whatDrivesUs.text}</ReactMarkdown>
          </Box>

          <OurTeam team={aboutUsObj.team} heading={aboutUsObj.teamSectionHeading} />

          <TeamOther team={aboutUsObj.teamOther} heading={aboutUsObj.teamOtherHeading} />

          <Friends friends={aboutUsObj.friends} heading={aboutUsObj.friendsSectionHeading} />
        </Box>
      </Block>
    </AboutUsWrapper>
  );
};

export default AboutUs;
