import React from 'react'
import { 
  makeStyles,
  Box,
  Typography,
  Link
} from '@material-ui/core'
import splitHeaderBg1 from '../../../static/graphics/splitHeaderBg-1.svg'
import splitHeaderBg2 from '../../../static/graphics/splitHeaderBg-2.svg'
import homeData from '../../../content/data/home.json'
import { ALT_FONT } from "../../gatsby-theme-hypercore/theme";

const useStyles = makeStyles(theme => ({
  centerAlign: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& > div': {
        maxWidth: '100%',
        '&::before': {
          width: "100vw!important",
        },
      },  
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.up('md')]: {
      height: '540px'
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '600px'
    },
    '&:nth-child(odd)': {
      '& > :first-child': {
        '&::before': {
          backgroundColor: '#FFF8ED',
          content: '""',
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: "50vw",
        },
      },
      '& > :nth-child(2)': {
        '&::before': {
          background: `url(${splitHeaderBg1}) no-repeat center center`,
          backgroundColor: '#B4A493',
          backgroundSize: 'cover',
          content: '""',
          height: '100%',
          position: 'absolute',
          right: 0,
          top: 0,
          width: "50vw",
        },
      }
    },
    '&:nth-child(even)': {
      '& > :first-child': {
        color: '#FFF',
        '&::before': {
          background: `url(${splitHeaderBg2}) no-repeat right bottom`,
          backgroundSize: '700px',
          backgroundColor: '#566875',
          content: '""',
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: "50vw",
          [theme.breakpoints.down('sm')]: {
            backgroundSize: '360px',
          },
        },
      },
      '& > :nth-child(2)': {
        '&::before': {
          backgroundColor: '#FFF8ED',
          content: '""',
          height: '100%',
          position: 'absolute',
          right: 0,
          top: 0,
          width: "50vw",
        },
      }
    },
  },
  panel: {
    textAlign: 'center',
    width: '50%',
    height: '100%',
    maxWidth: 860,
    padding: theme.spacing(5),
    [theme.breakpoints.down('lg')]: {
      maxWidth: 720,
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(8),
      paddingTop: theme.spacing(8),
      position: 'relative',
      width: '100%',
    },
  },
  panelAlign: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    height: '100%',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: 480,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      gap: 25,
      maxWidth: '70%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 250,
    },
    '& h3': {
      letterSpacing: '.02em',
      lineHeight: 1.6,
      marginBottom: 0,
      textTransform: 'uppercase',
    },
    '& p': {
      fontFamily: ALT_FONT,
      fontSize: 20,
      lineHeight: 1.6,
      margin: '0!important',
    }
  }
}))

const SplitHeader = () => {
  const classes = useStyles()

  const panels = homeData.panels.map((panel, i) => {
    return (
      <Box key={i} className={classes.panel}>
        <Box className={classes.panelAlign}>
          <Typography variant="h3" className="h3SplitHero">{panel.heading}</Typography>
          <Typography variant="body1" className="body1SplitHero">{panel.subheading}</Typography>
          <Link href={panel.source[0].link}>
            <Typography variant="overline" className="overlineSplitHero">
            {panel.source[0].copy}
            </Typography>
          </Link>
        </Box>
      </Box>
    )
  })

  var rowsE = []
  for (let i = 0; i < panels.length; i += 2) {
    const row = panels.slice(i, i + 2);
    rowsE.push(<Box key={i} className={classes.centerAlign}>{row[0]}{row[1]}</Box>)
  }

  return (
    <div className={classes.panels}>
      {rowsE.map((row) => {
        return row
      })}
    </div>
  )
}

export default SplitHeader
