import React from 'react';
import clsx from 'clsx';
import { Typography, Box, ButtonBase } from '@material-ui/core';
import iconPlus from '../../../static/icons/icon-plus.svg';
import { withStyles } from '@material-ui/styles';

/**
 * Wrapper for individual team member cards
 */
const MemberCardWrapper = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: 175,
    textAlign: 'left',
    // style overrides for displaying the full card in modal
    '&.mc_full': {
      maxWidth: '100%',
      flexDirection: 'row',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      '& .mc_name': { paddingRight: 20 },  
      '& .mc_icon': { display: 'none' },
      '& .mc_bio': { display: 'block', marginTop: 0, lineHeight: 1.5 },
      '& .mc_imageWrapper': { marginRight: theme.spacing(2), marginBottom: 0 },
      '& .mc_title': { fontSize: 14, marginBottom: theme.spacing(1) },
      '& .mc_thumbnail': {
        [theme.breakpoints.down('xs')]: {
          height: 125,
          marginBottom: 12,
          width: 125,
        },
      },  
    },
    '& .mc_imageWrapper': {
      alignItems: 'flex-start',
      marginBottom: 10,
      // add a focus state when the wrapper is a button
      '&.MuiButtonBase-root:focus': {
        outline: '2px solid #064493',
      },
    },
    '& .mc_icon': {
      bottom: 0,
      color: '#064493',
      position: 'absolute',
      right: -10,
    },
    '& .mc_thumbnail': {
      display: 'block',
      height: 175,
      objectFit: 'cover',
      width: 175,
    },
    // block styling on text elements so each is on their own line
    '& .mc_name, & .mc_title': {
      display: 'block',
      lineHeight: 1.4,
      marginBottom: theme.spacing(.75),
    },
    '& .mc_title': {
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 0,
    },
  },
}))(Box);

/**
 * Renders an individual team member card
 * - when `fullBio` or `full` is true, the full bio is shown (otherwise it is truncated to 100 chars)
 * - if `onOpenBio` is defined, the thumbnail is wrapped in a button with a + icon to indicate that the full bio can be shown
 */
const MemberCard = ({
  name,
  bio,
  thumbnail,
  title,
  full,
  fullBio,
  onOpenBio,
  ...props
}) => {
  const bioEl = full || fullBio ? <Typography className="mc_bio" variant="body2">{bio}</Typography> : false;
  // if onOpenBio is defined, wrap the thumbnail in a button
  const ImageWrapper = typeof onOpenBio === 'function' ? ButtonBase : Box;

  return (
    <MemberCardWrapper className={clsx({ mc_full: full })} {...props}>
      <ImageWrapper className="mc_imageWrapper" onClick={onOpenBio}>
        <img className="mc_thumbnail" src={thumbnail} alt={`${name}'s photo`} />
        <img src={iconPlus} className='mc_icon' aria-label={`Open full bio for ${name}`} />
      </ImageWrapper>
      <Box className="mc_contentWrapper">
        <Typography className="mc_name" variant="overline">
          {name}
        </Typography>
        <Typography className="mc_title" variant="caption">
          {title}
        </Typography>
        {/* {console.log(full)} */}
        {bioEl}
      </Box>
    </MemberCardWrapper>
  );
};

export default MemberCard;
