import React from 'react';
import { Block, Hero } from '@hyperobjekt/material-ui-website';
import HeroText from '../general/HeroText';
import ResourcesTable from './ResourcesTable';
import { DatahubPageStyles } from './styles/DatahubPageStyles';
import datahubData from '../../../content/data/datahub.json';
import PageHero from '../general/PageHero';
import datahubBg from '../../../static/graphics/datahubBg.svg'
import datahubLogo from '../../../static/icons/dataHubLogo.svg'

const DatahubPage = () => {
  const classes = DatahubPageStyles();

  return (
    <>
      <PageHero
        backgroundSize={'100% 100%'}
        image={datahubBg}
        heading={datahubData.hero.title}
        subheading={datahubData.hero.introText}
      />
      <Block>
        <img src={datahubLogo} className={classes.datahubLogo} />
        <div id="scrollTo">
          <ResourcesTable tableTitle={datahubData.tableTitle} introBlurb={datahubData.introBlurb} />
        </div>
      </Block>
    </>
  );
};

export default DatahubPage
