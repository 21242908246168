import React from 'react'

import {
  Box
} from "@material-ui/core";

import { Block } from '@hyperobjekt/material-ui-website';
import { NewsPageStyles } from './styles/NewsStyles'
import NewsItem from './NewsItem'
import newsObj from '../../../content/data/news.json'
import PageHero from '../general/PageHero';
import newsBg from '../../../static/graphics/newsBg.svg'

const NewsPage = () => {
  const classes = NewsPageStyles()

  return (
    <>
      <PageHero
        backgroundPosition={'right'}
        backgroundSize={'cover'}
        image={newsBg}
        heading={newsObj.hero.title}
        subheading={newsObj.hero.introText}
      />
     <Block className={classes.newsPageWrapper}>
        <Box className={classes.newsPage}>
          {newsObj &&
            newsObj.news.map(item => <NewsItem item={item} />)
          }
        </Box>
      </Block>
    </>
  )
}

export default NewsPage