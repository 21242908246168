import React from 'react';
import BaseDesktopNavigation from 'gatsby-theme-hypersite/src/header/desktop-navigation';
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    marginLeft: 'auto',
    "& .HypNavigation-listItem:hover a": {
      background: 'none',
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },
});

/**
 * Duplicate of base component desktop nav, but do not forward the partially active LinkProps
 */
const DesktopNavigation = ({ LinkProps, ...rest }) => {
  const newLinkProps = {
    ...LinkProps,
    partiallyActive: false,
  };

  return (
    <>
      <BaseDesktopNavigation LinkProps={newLinkProps} {...rest} />
    </>
  )
};

export default withStyles(styles)(DesktopNavigation);
