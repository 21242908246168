import React from 'react'
import {
  Typography,
  Box,
  makeStyles,
  // unstable_createMuiStrictModeTheme
} from "@material-ui/core";
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles(theme => ({
  textBox: {
    maxWidth: theme.spacing(75),
    '& > span': {
      color: theme.palette.secondary.main,
      display: 'block',
      marginBottom: theme.spacing(4),
    },
    '& h3': {
      lineHeight: 1.6,
      margin: '0!important',
      '& p': {
        margin: 0,
      },
    },
    // [theme.breakpoints.down('xs')]: {
    // },
  }
}))

const HeroText = (props) => {
  const { 
    heading, 
    subheading,
    textBoxStyles
  } = props

  const classes = useStyles()
  
  return (
    <Box className={textBoxStyles ? textBoxStyles : classes.textBox}>
      <Typography variant="overline">{heading}</Typography>
      <Typography variant="h3" className="h3Hero"><ReactMarkdown>{subheading}</ReactMarkdown></Typography>   
    </Box>
  )
}

export default HeroText
