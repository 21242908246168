import React from 'react';
import { 
  makeStyles,
  Box,
  Typography,
  Link
} from '@material-ui/core'
import { FONT } from "../../gatsby-theme-hypercore/theme";

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    paddingBottom: theme.spacing(10),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(10),
    width: '100%',
    '&:nth-child(even)': {
      backgroundColor: '#E8F5FF',

    },
  },
  wrapper: {
    display: 'flex',
    gap: 30,
    maxWidth: 1040,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  imgBox: {
    [theme.breakpoints.up('md')]: {
      '& img': {
        maxWidth: 240,
      },
    },
    [theme.breakpoints.only('sm')]: {
      '& img': {
        maxWidth: 200,
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& img': {
        maxWidth: '100%!important',
        width: '100%',
      },  
    },
  },
  textBox: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: 30,
  },
  description: {
    margin: '0!important',
  },
  findings: {
    color: '#064493',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    '& a': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      }
    }
  },
  findingsTitle: {
    color: 'black',
    fontWeight: 600,
    letterSpacing: '-0.44px',
    textDecoration: 'underline',
  },
  finding: {
    color: '#064493',
    fontFamily: FONT + '!important',
    fontWeight: 700 + '!important',
    letterSpacing: '-0.36px',
    lineHeight: 1.6 + '!important',
    margin: '0!important',
  },
}))

const ProjectItem = ({ image, title, description, findings, ...props }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        <Box className={classes.imgBox}>
          <img src={image} />
        </Box>
        <Box className={classes.textBox}>
          <Typography className={classes.title} variant="h2">{title}</Typography>
          {description && <Typography className={classes.description} variant="body1">{description}</Typography>}
          {(findings?.length > 0) && <Box className={classes.findings}>
            <Typography variant="h3" className={classes.findingsTitle}>KEY FINDINGS</Typography>
            {(findings?.length > 0) && findings.map((item, i) => {
              return <a key={i} href={item.link}><Typography variant="body1" className={classes.finding}>{item.finding}</Typography></a>
            })}
          </Box>}
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectItem
