import { makeStyles } from '@material-ui/core';
import heroBg from '../../../../static/graphics/projectsBg.svg'

export const ProjectsPageStyles = makeStyles(theme => ({
  sectionTitle: {
    marginBottom: 35,
    fontSize: '15px!important',
    fontWeight: 700 + '!important',
    letterSpacing: '.03em',
    textAlign: 'center',
    textTransform: 'uppercase',
    // '& + div': {
    //   marginTop: '35px!important',
    // }
  },
  projects: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(6),
  },
  upcomingProjects: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(6),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 1248,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  reserchProducts: {
    display: 'flex',
    flexDirection: 'column',
    gap: 35,
    marginBottom: 120,
    '& h6': {
      marginBottom: 0,
    }
  },
}));
