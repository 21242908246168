import React, { useState, useEffect } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import fields from '../../../content/data/signup_form.json';
import { Typography, TextField, makeStyles } from '@material-ui/core';
import { FONT } from "../../gatsby-theme-hypercore/theme";
import RoundedButton from '../../components/general/RoundedButton'

const useStyles = makeStyles(theme => ({
  formWrapper: {
    backgroundColor: '#E8F5FF',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  signUpForm: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 1280,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(7),
    paddingTop: theme.spacing(7),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      rowGap: 24,
    },
  },
  form_text: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    letterSpacing: '-.02em',
    lineHeight: '1.6!important',
    marginLeft: theme.spacing(4) + 'px!important',
    marginRight: theme.spacing(4) + 'px!important',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      marginLeft: '0px!important',
      marginRight: '0px!important',
      textAlign: 'center',
    },
  },
  hideInput: {
    '& :not(h6,span,b)': {
      display: 'none',
    }
  },
  textField: {
    backgroundColor: 'white',
    height: 60,
    justifyContent: 'center',
    padding: '10px!important',
    width: 350,
    '& div::before': {
      display: 'none',
    },
    '& div::after': {
      display: 'none',
    },
    '& input': {
      fontFamily: FONT,
      fontSize: 22,
      fontWeight: 700,
      padding: 0,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
      },
    },
    '& small': {
      display: 'none',
      fontSize: 13,
      left: -10,
      position: 'absolute',
      top: 'calc(100% + 20px)',
    },
  },
  invalid: {
    boxShadow: '0px 0px 4px 2px #ff3333',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '16px!important',
    },
    '& + button': {
      [theme.breakpoints.down('sm')]: {
        marginBottom: '16px!important',
      },  
    },
    '& small': {
      display: 'flex',
    },
  },
  submitButton: {
    backgroundColor: '#064493!important',
    borderColor: '#064493!important',
    borderRadius: '0!important',
    height: 60,
    padding: '10px 42px!important',
  },
  submitButtonInvalid: {
    borderColor: 'red!important',
    borderRadius: '1em!important',
  },
  submitButtonText: {
    color: "white",
    fontSize: 22,
    fontWeight: 700,
    letterSpacing: '-.02em',
    textTransform: 'none',
  }
}))

const SignUpForm = () => {
  const [Data, setData] = useState();
  const [valid, setValid] = useState(true);
  const [thanksMessage, setThanksMessage] = useState();

  const { form_text, input_placeholder, button_text } = { ...fields }

  const handleSubmit = e => {
    e.preventDefault()

    const email = e.target[0].value

    // validate email address before submitting
    const check = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email)
    if (check) {
      addToMailchimp(e.target[0].value)
      .then(data => {
        if (data.result == 'success') {
          setThanksMessage(<span><b>Success! </b> {data.msg}</span>)
        } else {
          setThanksMessage(<span><b>Error: </b> {data.msg}</span>)
        }
      })
    } else {
      setValid(false)
    }
  }

  const disableInvalid = () => {
    setValid(true)
  }
 
  const classes = useStyles()

  return (
    <div className={classes.formWrapper}>
      <form onSubmit={handleSubmit} className={ thanksMessage ? `${classes.signUpForm} ${classes.hideInput}` : classes.signUpForm }>
        <Typography className={ classes.form_text } variant={ thanksMessage ? 'h6' : 'h2' }>{ thanksMessage || form_text }</Typography>
        <TextField
          id="email"
          name="email"
          className={ valid ? classes.textField : `${classes.textField} ${classes.invalid}` }
          placeholder={ input_placeholder }
          // InputProps={{ disableUnderline: true }}
          onChange={ disableInvalid }
          InputProps={{
            endAdornment: (
              <small>Please enter a valid email address</small>
            ),
          }}
        />
        <RoundedButton
          type={"submit"}
          text={ button_text }
          buttonStyles={setValid ? classes.submitButton : classes.submitButtonInvalid}
          textStyles={classes.submitButtonText}
        />
      </form>
    </div>
  )
}

export default SignUpForm
